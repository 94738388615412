import { usePathname, useRouter } from 'next/navigation'
import { useCallback } from 'react'

import { useRoles } from 'utils/hooks/useRoles'
import { blocks, permissions } from 'configs/policies'
import roles from 'configs/roles'

type Role = (typeof roles)[keyof typeof roles]

interface Permission {
  allow: string[]
  block?: string[]
}

export function usePermission() {
  const { userHasOneOfRoles } = useRoles()
  const router = useRouter()
  const pathname = usePathname()

  const basePath = `/${pathname.split('/')[1]}`

  const currentPathPermissions = useCallback((): Permission => {
    // Route Path defined on policy
    if (permissions?.[pathname]) {
      return {
        allow: permissions?.[pathname]?.roles ?? permissions?.[pathname] ?? [],
        block: blocks?.[pathname]?.roles ?? blocks?.[pathname] ?? [],
      }
    }

    // Base Path defined on policy
    if (permissions?.[basePath]) {
      return {
        allow: permissions?.[basePath]?.roles ?? permissions?.[basePath] ?? [],
        block: blocks?.[basePath]?.roles ?? blocks?.[basePath] ?? [],
      }
    }

    return {
      allow: [],
      block: [],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, router])

  const checkPermissions = useCallback(
    (permissions: Permission = { allow: [], block: [] }): boolean => {
      if (permissions.allow?.length === 0) {
        return true
      } else {
        const hasValidRoles = userHasOneOfRoles(Object.values(roles) as Role[])
        const hasModulePermissions = userHasOneOfRoles(
          permissions.allow as Role[]
        )
        const blocked = userHasOneOfRoles(permissions.block ?? [])

        return hasValidRoles && hasModulePermissions && !blocked
      }
    },
    [userHasOneOfRoles]
  )

  return {
    checkPermissions,
    currentPathPermissions,
  }
}
