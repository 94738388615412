import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { Session } from 'types/auth'
import type { CustomError as ICustomError } from 'types/custom-error'

import { getSession, setAuthToAPI } from 'utils/auth'
import {
  CustomError,
  ForbiddenError,
  InternalServerError,
  NotFoundError,
  ServiceUnavailableError,
  SessionError,
} from 'utils/customError'
import { baseAPIPathGuruCore, baseAPIPathGuruKS, baseUrl } from 'configs/api'
import { BCKS_SESSION_STORAGE_KEY } from 'configs/auth'
import { BCKS_REGION_PREFIX } from 'configs/roles'

import { handleDownloadFile } from './file'
import { getRegionType } from './region'
import { constructSessionId } from './session'

const keyRateLimitBypass = process.env.NEXT_PUBLIC_KEY_RATE_LIMIT_BYPASS

enum ErrorCode {
  InternalServerError = 400,
  SessionError = 401,
  ForbiddenError = 403,
  NotFoundError = 404,
  InternalServerError2 = 500,
  ServiceUnavailableError = 503,
}

type CommonErrorHandlerFn = (
  status: any,
  path: string,
  error: any,
  errorResponse?: any
) => void

interface QueryApiConfig<TQueryFnData = unknown> {
  axiosConfig?: AxiosRequestConfig
  queryConfig?: UseQueryOptions<TQueryFnData>
  storageKey?: string
  storageSerializer?: (any) => any
  errorHandlerFn?: CommonErrorHandlerFn
}

interface InfiniteQueryApiConfig<TQueryFnData = unknown> {
  axiosConfig?: AxiosRequestConfig
  queryConfig?: UseInfiniteQueryOptions<TQueryFnData>
  storageKey?: string
  storageSerializer?: (any) => any
  errorHandlerFn?: CommonErrorHandlerFn
}

let sessionError: ICustomError | null = null

interface MutationApiConfig<
  TData = unknown,
  TError = unknown,
  TVariables = void,
> {
  axiosConfig?: AxiosRequestConfig
  mutationConfig?: UseMutationOptions<TData, TError, TVariables>
  storageKey?: string
  storageSerializer?: (any) => any
  errorHandlerFn?: CommonErrorHandlerFn
  /**
   * guru token for CCT is passed through query param
   */
  tokenFromQueryParam?: string | string[]
}

export const handleCommonErrors = (status, path: string, error: any) => {
  switch (status) {
    case ErrorCode.InternalServerError:
      throw new InternalServerError(path, error)
    case ErrorCode.SessionError:
      throw new SessionError(path, error)
    case ErrorCode.ForbiddenError:
      throw new ForbiddenError(path, error)
    case ErrorCode.NotFoundError:
      throw new NotFoundError(path, error)
    case ErrorCode.InternalServerError2:
      throw new InternalServerError(path, error)
    case ErrorCode.ServiceUnavailableError:
      throw new ServiceUnavailableError(path, error)
    default:
      throw error
  }
}

export const createFormData = (object: any): FormData => {
  const formData = new FormData()
  Object.keys(object).forEach((key) => formData.append(key, object[key]))

  return formData
}

export function useQueryApi<TQueryFnData, TQueryFnError = CustomError>(
  path: string,
  {
    axiosConfig = {},
    queryConfig = {},
    storageKey = BCKS_SESSION_STORAGE_KEY,
    storageSerializer,
    errorHandlerFn = handleCommonErrors,
  }: QueryApiConfig<TQueryFnData> = {
    axiosConfig: {},
    queryConfig: {},
    storageKey: BCKS_SESSION_STORAGE_KEY,
    storageSerializer: undefined,
  }
): UseQueryResult<TQueryFnData, TQueryFnError> {
  let queryKey: any = []

  if (!!axiosConfig.data) queryKey.push(axiosConfig.data)
  if (!!axiosConfig.params) queryKey.push(axiosConfig.params)
  if (queryKey.length > 0) {
    queryKey = [path, ...queryKey]
  } else {
    queryKey = path
  }

  return useQuery<TQueryFnData, TQueryFnError>(
    queryConfig.queryKey || queryKey,
    (): Promise<TQueryFnData> => {
      return api<TQueryFnData>(
        path,
        axiosConfig,
        storageKey,
        storageSerializer,
        errorHandlerFn
      )
    },
    queryConfig
  )
}

export function useInfiniteQueryApi<TQueryFnData, TQueryFnError = CustomError>(
  path: string,
  {
    axiosConfig = {},
    queryConfig = {},
    storageKey = BCKS_SESSION_STORAGE_KEY,
    storageSerializer,
    errorHandlerFn = handleCommonErrors,
  }: InfiniteQueryApiConfig<TQueryFnData> = {
    axiosConfig: {},
    queryConfig: {},
    storageKey: BCKS_SESSION_STORAGE_KEY,
    storageSerializer: undefined,
  }
): UseInfiniteQueryResult<TQueryFnData, TQueryFnError> {
  let queryKey: any = []

  if (!!axiosConfig.params) queryKey.push(axiosConfig.params)
  if (queryKey.length > 0) {
    queryKey = [path, ...queryKey]
  } else {
    queryKey = path
  }

  return useInfiniteQuery<TQueryFnData, TQueryFnError>(
    queryKey,
    async ({ pageParam }): Promise<TQueryFnData> => {
      return api<TQueryFnData>(
        path,
        {
          ...axiosConfig,
          params: {
            ...axiosConfig.params,
            ...pageParam,
          },
        },
        storageKey,
        storageSerializer,
        errorHandlerFn
      )
    },
    queryConfig
  )
}

export const paramsSerializer = (params?: Record<string, any>): string =>
  stringify(params ?? {}, { arrayFormat: 'none' })

const guruTokenShallowValidation = (storageSerializer, storageKey, path) => {
  const session = storageSerializer(getSession(storageKey))
  if (session.hasOwnProperty('guruToken') && !session.guruToken) {
    throw new SessionError(path, null)
  }
}

const createRequestApi =
  <T>(
    path: string,
    options: AxiosRequestConfig = {},
    storageKey: string,
    storageSerializer
  ) =>
  (): AxiosPromise<T> => {
    const url = `${baseUrl}${path}`

    const session = storageSerializer(getSession(storageKey))
    options.headers = {
      ...options.headers,
      ...(!!keyRateLimitBypass && path.startsWith(`${baseAPIPathGuruKS}/`)
        ? { 'x-ratelimit-bypass': keyRateLimitBypass }
        : {}),
      Authorization: `Bearer ${session.guruToken || session.accessToken}`,
    }

    return axios({
      ...options,
      paramsSerializer,
      url,
    })
  }

const handleError = (error: any) => {
  // Force logout when session expired / un-refresh-able
  if (error instanceof SessionError && !sessionError) {
    sessionError = error

    const pathname = window.location.pathname || '/home'
    window.location.href = `/logout?from=${pathname}&target=/login&error=SessionError`
  }

  if (error instanceof CustomError) {
    throw error
  }
}

export async function api<T>(
  path: string,
  options: AxiosRequestConfig = {},
  storageKey: string = BCKS_SESSION_STORAGE_KEY,
  storageSerializer: (any) => any = (param) => param,
  errorHandlerFn: CommonErrorHandlerFn = handleCommonErrors
): Promise<T> {
  const requestApi = createRequestApi<T>(
    path,
    options,
    storageKey,
    storageSerializer
  )

  try {
    await refreshSession(storageKey, storageSerializer)
    guruTokenShallowValidation(storageSerializer, storageKey, path)
    const response = await requestApi()
    return response.data
  } catch (error) {
    handleError(error)
    // save navigation because error type object can be other than AxiosError
    const status = error?.response?.status
    errorHandlerFn(status, path, error, error.response)
  }
}

export async function apiFileDownload(
  path: string,
  options: AxiosRequestConfig = {},
  storageKey: string = BCKS_SESSION_STORAGE_KEY,
  storageSerializer: (any) => any = (param) => param,
  errorHandlerFn: CommonErrorHandlerFn = handleCommonErrors,
  fileName = ''
) {
  const requestApi = createRequestApi<Blob>(
    path,
    { ...options, responseType: 'blob' },
    storageKey,
    storageSerializer
  )

  try {
    await refreshSession(storageKey, storageSerializer)
    guruTokenShallowValidation(storageSerializer, storageKey, path)
    const { data, headers } = await requestApi()

    // headers.content-disposition example value: "attachment; filename=20220912_Prov. Jawa Timur_Kab. Gresik_PAUDQ_SD_SMP_TK.xlsx"
    // Might need to refactor IF other file download API in BCKS doesn't use the same standard
    handleDownloadFile(
      data,
      fileName || headers['content-disposition'].split('=')[1]
    )
  } catch (error) {
    handleError(error)
    // save navigation because error type object can be other than AxiosError
    const status = error?.response?.status
    errorHandlerFn(status, path, error, error.response)
  }
}

let refreshTokenPromise: AxiosPromise<Session> | null

export async function refreshSession(
  storageKey: string,
  storageSerializer: (any) => any = (param) => param
): Promise<Session> {
  const session = storageSerializer(getSession(storageKey))
  const isGuruToken = !!session.guruToken
  const sessionExpiryTime = new Date(session.expiredAt).getTime()
  const currentTime = new Date().getTime()
  const isTokenExpired = sessionExpiryTime < currentTime

  const path = `${baseAPIPathGuruCore}/v1alpha2/guru-token/refresh`

  guruTokenShallowValidation(storageSerializer, storageKey, null)

  try {
    if (isTokenExpired) {
      const apiUrl = baseUrl + path

      let data

      if (!refreshTokenPromise) {
        const guruTokenAxiosConfig: AxiosRequestConfig = {
          url: apiUrl,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${session.guruToken}`,
          },
        }
        const accessTokenAxiosConfig: AxiosRequestConfig = {
          url: setAuthToAPI(apiUrl, session),
          method: 'GET',
        }
        const axiosConfig: AxiosRequestConfig = isGuruToken
          ? guruTokenAxiosConfig
          : accessTokenAxiosConfig
        refreshTokenPromise = axios(axiosConfig) as AxiosPromise<Session>
      }
      data = (await refreshTokenPromise).data
      data = data.data || data
      const userRegionGroup = data.user.groups.find((group) =>
        group.name.startsWith(BCKS_REGION_PREFIX)
      )
      const userRegionId =
        session?.userRegion?.id ||
        userRegionGroup?.name.replace(`${BCKS_REGION_PREFIX}_`, '') ||
        ''
      window.localStorage.setItem(
        storageKey,
        JSON.stringify({
          ...data,
          sessionId: session?.sessionId ?? constructSessionId(data.user),
          userRegion: {
            id: userRegionId,
            type: getRegionType(userRegionId),
          },
        })
      )
      refreshTokenPromise = null
      return data
    } else {
      return session
    }
  } catch (error) {
    refreshTokenPromise = null
    throw new SessionError(path, error)
  }
}

export function useMutationApi<TData, TError, TVariables>(
  path: string | ((data) => string),
  {
    axiosConfig = {},
    mutationConfig = {},
    storageKey = BCKS_SESSION_STORAGE_KEY,
    storageSerializer,
    errorHandlerFn = handleCommonErrors,
  }: MutationApiConfig<TData, TError, TVariables> = {
    axiosConfig: {},
    mutationConfig: {},
    storageKey: BCKS_SESSION_STORAGE_KEY,
    storageSerializer: undefined,
  }
) {
  return useMutation<TData, TError, TVariables>((data: any): Promise<TData> => {
    const pathApi = typeof path === 'function' ? path(data) : path
    return api<TData>(
      pathApi,
      {
        ...axiosConfig,
        ...(axiosConfig.method === 'GET' ? { params: data } : {}),
        data,
      },
      storageKey,
      storageSerializer,
      errorHandlerFn
    )
  }, mutationConfig)
}

export { getSession } from 'utils/auth'
