import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { signOut, SignOutParams, useSession } from 'next-auth/react'
import React from 'react'
import type { AuthContextProps, Session } from 'types/auth'

import { useAsync } from 'utils/hooks/useAsync'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { usePermission } from 'utils/hooks/usePermission'
import { getRegionType } from 'utils/region'
import { constructSessionId } from 'utils/session'
import { baseUrlAPIGuruCore, baseUrlAPIGuruKS } from 'configs/api'
import { BCKS_SESSION_STORAGE_KEY } from 'configs/auth'
import { BCKS_REGION_PREFIX, ROLES_ALLOWED_LOGIN } from 'configs/roles'
import { NONLOGIN_PATHS } from 'configs/routes'

import { AuthContext } from './AuthContext'
import { WithRegionContext } from '../useRegion'

function isProtectedRoutes(pathname: string): boolean {
  return !NONLOGIN_PATHS.includes(pathname)
}

async function fetchToken(idToken: string, provider: string): Promise<Session> {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: `${baseUrlAPIGuruCore}/v1alpha2/login`,
      method: 'POST',
      data: {
        grantType: provider,
        token: idToken,
      },
    }
    const { data } = (await axios(requestConfig)) as AxiosResponse<{
      data: Session
    }>

    const payload = data.data
    const { data: responseGetGroups } = await axios({
      url: `${baseUrlAPIGuruKS}/v1/ks/usermana/groups`,
      method: 'GET',
      params: {
        email: payload.user.email,
        limit: 100,
      },
      headers: {
        Authorization: `Bearer ${payload.guruToken}`,
      },
    })
    const groups = responseGetGroups.data.map((group) => group.name)
    const isUserAllowed = ROLES_ALLOWED_LOGIN.some((role) =>
      groups.some((group) => group.startsWith(role))
    )
    if (isUserAllowed) {
      return {
        ...payload,
        user: {
          ...payload.user,
          groups: responseGetGroups.data,
        },
      }
    }
    throw {
      message: 'Akun Anda tidak memiliki akses untuk aplikasi ini',
    }
  } catch {
    throw {
      message: 'Akun Anda tidak memiliki akses untuk aplikasi ini',
    }
  }
}

export function PermissionGuard({ children }) {
  const router = useRouter()
  const { checkPermissions, currentPathPermissions } = usePermission()
  const permitted = checkPermissions(currentPathPermissions())
  if (permitted) {
    return <WithRegionContext>{children}</WithRegionContext>
  }
  router.replace('/404')
  return null
}

export function AuthGuard(props) {
  const router = useRouter()
  const query = useSearchParams()
  const pathname = usePathname()
  const protectedRoutes = isProtectedRoutes(pathname)
  const { data: session, status: sessionStatus } = useSession()
  const { data, status, error, run } = useAsync<Session, AxiosError>()
  const bcksUserSession = useLocalStorage<Session>(
    BCKS_SESSION_STORAGE_KEY,
    null
  )
  const account = session
  const isSessionLoading = sessionStatus === 'loading'
  const hasUser: boolean =
    !!bcksUserSession && Object.keys(bcksUserSession).length > 0

  const logoutFn = async (signOutParams?: SignOutParams<false>) => {
    await invalidateGuruToken()
    await bcksUserSession.remove()

    await signOut(signOutParams)
  }

  const invalidateGuruToken = React.useCallback(async () => {
    const guruToken = bcksUserSession?.data?.guruToken
    const path = '/v1alpha2/logout'
    if (hasUser) {
      try {
        const { data } = (await axios({
          url: `${baseUrlAPIGuruCore}${path}`,
          method: 'POST',
          data: {
            grant_type: session?.provider,
            token: session?.idToken,
          },
          headers: {
            Authorization: `Bearer ${guruToken}`,
          },
        })) as AxiosResponse

        return data
      } catch {
        return Promise.resolve()
      }
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUser, session])

  React.useEffect(() => {
    if (pathname === '/login' && account && status === 'idle') {
      run(fetchToken(account.idToken, account.provider))
    }
  }, [pathname, account, status, run])

  React.useEffect(() => {
    if (data) {
      const userRegionGroup = data.user.groups.find((group) =>
        group.name.startsWith(BCKS_REGION_PREFIX)
      )
      const userRegionId =
        bcksUserSession?.data?.userRegion?.id ||
        userRegionGroup?.name.replace(`${BCKS_REGION_PREFIX}_`, '') ||
        ''
      bcksUserSession.set({
        ...data,
        sessionId:
          bcksUserSession?.data?.sessionId ?? constructSessionId(data.user),
        userRegion: {
          id: userRegionId,
          type: getRegionType(userRegionId),
        },
      })
    }
    // eslint-disable-next-line
  }, [data])

  React.useEffect(() => {
    if (!!error) {
      logoutFn({ redirect: false })
    }
    // eslint-disable-next-line
  }, [error])

  const context: AuthContextProps = {
    bcksUserSession: bcksUserSession?.data ?? null,
    userHasNoAccess: !!error,
    async logout() {
      const callbackUrl = new URL('/login', window.location.origin)
      if (!!query.get('from')) {
        callbackUrl.searchParams.append('from', query.get('from') as string)
      }

      logoutFn({
        callbackUrl: callbackUrl.toString(),
      })
    },
  }

  if (isSessionLoading) {
    return null
  }

  if (protectedRoutes) {
    if (bcksUserSession.data) {
      return (
        <AuthContext.Provider value={context} {...props}>
          <PermissionGuard {...props} />
        </AuthContext.Provider>
      )
    }

    router.push(
      `/login?${new URLSearchParams({
        callbackUrl: pathname,
      }).toString()}`
    )
    return null
  }

  return <AuthContext.Provider value={context} {...props} />
}
