import React from 'react'

function log(key: string, error: any) {
  // eslint-disable-next-line
  console.log('error in local storage key:', key)

  console.error(error)
}

export function useLocalStorage<T = any>(key: string, initialValue) {
  const getValue = (): T => {
    try {
      if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      }
      return initialValue
    } catch (error) {
      log(key, error)
      return initialValue
    }
  }

  const [storedValue, setStoredValue] = React.useState<T>(getValue())

  const setValue = (value: T) => {
    try {
      setStoredValue(value)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value))
      }
    } catch (error) {
      log(key, error)
    }
  }

  const removeValue = () => {
    try {
      setStoredValue(null)
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key)
      }
    } catch (error) {
      log(key, error)
    }
  }

  return { data: storedValue, set: setValue, remove: removeValue }
}
