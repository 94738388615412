import { SessionProvider as NextAuthProvider } from 'next-auth/react'
import React from 'react'
import { resolveValue, Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'

import { historyUtils } from '@wartek-id/fe-toolbox'
import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'
import { ToastProvider } from '@wartek-id/toast'

import { AuthGuard } from 'utils/hooks/useAuth'

import { Layout as BaseLayout } from 'components/Layout/Layout'
import { Seo } from 'components/Seo'

import 'styles/global.css'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

const App = ({ Component, pageProps, router }) => {
  const Layout = Component.Layout ?? BaseLayout

  return (
    <Layout {...Component.layoutOpts}>
      <Component {...pageProps} key={router.route} />
    </Layout>
  )
}

const BCKSToast = ({ children }) => (
  <div
    className="flex w-[95vw] rounded bg-[#2F3031] p-3"
    data-testid="bcks-toast"
  >
    <Icon color="inverse">check_circle</Icon>
    <Text color="inverse" className="ml-2">
      {children}
    </Text>
  </div>
)

const AppProvider = ({
  Component,
  pageProps: { session, ...pageProps },
  router,
}) => {
  React.useEffect(() => {
    historyUtils.storeAppInitialPath()
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  return (
    <ToastProvider autoDismiss placement="top-center">
      <QueryClientProvider client={queryClient}>
        <NextAuthProvider session={session}>
          <AuthGuard {...pageProps}>
            {/* Toaster element here is a custom version only used in BCKS since the current one in Seragam UI doesn't support custom toast component */}
            <Toaster containerClassName="mt-20">
              {(t) => <BCKSToast>{resolveValue(t.message, t)}</BCKSToast>}
            </Toaster>
            <Seo />
            <App Component={Component} pageProps={pageProps} router={router} />
          </AuthGuard>
        </NextAuthProvider>
      </QueryClientProvider>
    </ToastProvider>
  )
}

export default AppProvider
