import clsx from 'clsx'
import React from 'react'

import { Text } from '@wartek-id/text'

import { DesktopNavbar } from 'components/DesktopNavbar'
import { DesktopSidebar } from 'components/DesktopSidebar'

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  hasHeader?: boolean
  headerTitle?: string
  hasSidebar?: boolean
  classNameProp?: string
  classNameLayout?: string
  activeSidebarKey?: string
  session?: any
}

export const Layout = ({
  children,
  hasHeader = false,
  hasSidebar = false,
  activeSidebarKey = '',
  headerTitle = '',
  classNameProp = '',
  classNameLayout = '',
}: LayoutProps) => {
  return (
    <div className={clsx('min-h-screen bg-[#F8F8F8]', classNameLayout)}>
      {hasHeader && (
        <DesktopNavbar>
          {headerTitle && <Text variant="heading-lg">{headerTitle}</Text>}
        </DesktopNavbar>
      )}

      {hasSidebar && <DesktopSidebar activeKey={activeSidebarKey} />}
      <div
        className={clsx(
          'overflow-y-auto bg-[#F8F8F8] px-6 py-8',
          {
            'ml-[316px]': hasSidebar,
          },
          classNameProp
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout
