import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { Icon } from '@wartek-id/icon'
import { List, ListItem } from '@wartek-id/list'
import { Popover, PopoverContent, PopoverTrigger } from '@wartek-id/popover'
import { Text } from '@wartek-id/text'

import { useAuth } from 'utils/hooks/useAuth'
import { usePermission } from 'utils/hooks/usePermission'
import { useRegionContext } from 'utils/hooks/useRegion'
import { ROLES_ALLOWED_CHANGE_REGION } from 'configs/roles'

import styleComponentOverride from './component-override.module.css'

const defaultNavbarTitle =
  'Sistem Pengangkatan Kepala Sekolah dan Pengawas Sekolah'

interface DesktopNavbarProps {
  children?: React.ReactNode
}

interface ProfileMenu {
  text: string
  id: string
  href: string
  roles?: string[] // No roles = accessible to everyone
}

const profileMenuList: ProfileMenu[] = [
  {
    text: 'Ganti Daerah',
    id: 'region',
    href: '/region',
    roles: ROLES_ALLOWED_CHANGE_REGION,
  },
  {
    text: 'Keluar',
    id: 'logout',
    href: '/logout',
  },
]

export const DesktopNavbar = (props: DesktopNavbarProps) => {
  const { children } = props
  const { bcksUserSession } = useAuth()
  const { user = null } = bcksUserSession || {}
  const { regionDetail, isLoadingRegionDetail } = useRegionContext()
  const { checkPermissions } = usePermission()
  const permittedProfileMenuList = profileMenuList.filter((profileMenu) =>
    checkPermissions({ allow: profileMenu.roles || [] })
  )

  const UserRegion = () => {
    return (
      !isLoadingRegionDetail && (
        <>
          <Icon className="mr-2">location_on</Icon>
          <Text
            variant="action"
            className="shrink-0 uppercase"
            data-testid="text-region"
          >
            {regionDetail?.name || 'Daerah belum dipilih'}
          </Text>
        </>
      )
    )
  }

  return (
    <nav
      data-testid="navbar"
      className="sticky top-0 z-50 flex flex-row items-center justify-between bg-white px-12 py-3 shadow"
    >
      <div className="flex flex-row items-center">
        <Link href="/" aria-label="Logo" className="flex gap-3">
          <Image
            src="/images/logo-kemdikbud.png"
            alt="Kemdikbud"
            width={44}
            height={44}
          />
          <Image
            src="/images/logo-ruang-gtk.svg"
            alt="PMM"
            width={60}
            height={44}
          />
        </Link>
        <div className="ml-4">
          {children || <Text variant="heading-lg">{defaultNavbarTitle}</Text>}
        </div>
      </div>

      <div className="flex flex-row">
        <Popover offset={[0, 14]}>
          <PopoverTrigger className="flex items-center">
            <UserRegion />
            <Icon className="ml-2">expand_more</Icon>
          </PopoverTrigger>

          <PopoverContent
            className={styleComponentOverride.popoverContentContainer}
          >
            <div className="bg-[#FAFAFA] p-4">
              <Text variant="action">{user?.name}</Text>
              <Text color="subdued" className="overflow-hidden text-ellipsis">
                {user?.email}
              </Text>
            </div>
            <List
              type="menu"
              className={styleComponentOverride.navbarProfileMenuBody}
            >
              {permittedProfileMenuList.map(({ href, text }, index) => (
                <ListItem
                  key={`profile-items-${index}`}
                  className={styleComponentOverride.navbarProfileMenu}
                >
                  <Link
                    href={href}
                    passHref
                    className={styleComponentOverride.navbarProfileMenuLink}
                  >
                    <Text>{text}</Text>
                  </Link>
                </ListItem>
              ))}
            </List>
          </PopoverContent>
        </Popover>
      </div>
    </nav>
  )
}
